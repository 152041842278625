<template>
  <div
    class="InputSelectOption"
    :class="{ isSelected }"
    tabindex="0"
    @keyup.enter.space="handleSelect"
    @click="handleSelect"
    @focus="handleFocus">
    <slot>
      <div
        class="__label">
        {{ option.label }}
      </div>

      <IconCheckmark
        :size="20"
        :is-checked="isSelected"
      />
    </slot>
  </div>
</template>


<script>
import IconCheckmark from '@/components/IconCheckmark.vue';

export default {
  name: 'InputSelectOption',

  components: {
    IconCheckmark,
  },

  props: {
    option: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'select',
    'focus',
  ],

  methods: {
    handleSelect() {
      this.$emit('select', this.option);
    },

    handleFocus() {
      this.$emit('focus', this.option);
    },

    focus() {
      this.$el.focus();
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.InputSelectOption {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  white-space: nowrap;
  cursor: pointer;
  .__label {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// isFocused
.InputSelectOption:focus,
.InputSelectOption:hover {
  outline: none;
  background-color: $light;
}

// isSelected
.InputSelectOption.isSelected {
  background: rgba($light-blue, 0.2);
}
</style>
